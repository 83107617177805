<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="易年审" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div class="hset">
      <div class="hes_a" @click="tab(1)" :class="actv == 1?'cors':''">
        上线验车预约
        <div class="xian" v-if="actv == 1"></div>
      </div>
      <div class="hes_a" @click="tab(2)" :class="actv == 2?'cors':''">
        六年免检领标
        <div class="xian" v-if="actv == 2"></div>
      </div>
    </div>
    <div style="padding-top:116px; text-align: center;">
      <div class="mian">
        <div class="ban">办理条件</div>
        <div class="tesx">
          <span class="dian">·</span>已在公安机关交通管理部门登记的车辆
        </div>

        <div class="tesx" v-if="actv == 1">
          <span class="dian">·</span>涉及该车辆的交通违法或事故均已处理完毕
        </div>
        <div class="tesx">
          <span class="dian">·</span>交强险保单需要在有效期内
        </div>
        <div class="tesx" v-if="actv == 2">
          <span class="dian">·</span>涉及该车辆的交通违法已处理完毕
        </div>
        <div class="tesx" v-if="actv == 2">
          <span class="dian">·</span>车辆从未发生过致人伤亡的交通事故
        </div>
        <div class="tesx" v-if="actv == 1">
          <span class="dian">·</span>外观整洁，各种灯具、仪表齐全有效
        </div>
        <div class="tesx" v-if="actv == 1">
          <span class="dian">·</span>车辆性能符合机动车运行安全技术的条件
        </div>
        <div class="tesx" v-if="actv == 1">
          <span class="dian">·</span>
          车辆特征、技术参数与注册登记一致，无擅自改变车辆已登记的结构，构造或者特征等不允许变更的情况
        </div>
        <div class="tesx" v-if="actv == 2">
          <span class="dian">·</span>全国符合6年免检条件的车辆（9座及以下"非营运"使用性质的小型汽车、摩托车）
        </div>
      </div>
      <van-button
        type="primary"
        color="#0d906e"
        class="anniu"
        @click="toinqs"
      >{{actv==1?'立即预约':'马上领标'}}</van-button>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: {},
      actv: 1,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    toinqs() {
      if (this.actv == 1) {
        // this.$router.push({
        //   path: "Inspection_car"
        // });
           window.location.href = "/Inspection_car";
      }else{
          this.$router.push({
          path: "exemption"
        });
      }
    },
    tab(e) {
      this.actv = e;
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo ? this.userInfo.id : ""
        // uid: 1661
      };
      orderModel
        .count(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      this.$router.push({
        path: "user"
      });
      // this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.hset {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  overflow: hidden;
}
.hes_a {
  width: 50%;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  float: left;
  color: #666;
  position: relative;
}
.xian {
  background: #0d906e;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1.5px;
  margin-bottom: 13px;
  width: 40%;
  margin-left: -20%;
}
.cors {
  color: #0d906e;
}
.mian {
  background: rgb(242, 251, 252);
  margin: 0 15px;
  text-align: left;
  border-radius: 10px;
  font-size: 15px;
  padding: 15px 15px 25px 15px;
  color: #666;
}
.tesx {
  // padding-left: 15px;
  padding: 2px 0 2px 15px;
}
.dian {
  font-weight: bold;
  margin-right: 5px;
  color: #000;
  float: left;
}
.ban {
  // font-weight: bold;
  color: #000;
  font-size: 16px;
  margin-bottom: 2px;
}
.anniu {
  position: absolute;
  bottom: 0;
  width: 90%;
  height: 40px;
  left: 50%;
  margin-left: -45%;
  margin-bottom: 70px;
}
</style>